.content {
  padding: $content-padding;
  flex: 1;
  direction: ltr;

  @include media-breakpoint-down(md) {
    width: 100vw;
    max-width: 100vw;
  }

  @include media-breakpoint-down(lg) {
    padding: $spacer * 1.5;
  }
}

.content-grayout {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
}
