.absolute-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 0;
}
