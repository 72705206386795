.input-group-navbar {
  .form-control,
  .btn {
    height: $input-height-lg;
    background: $navbar-input-bg;
    box-shadow: none;
    border: 0;
    padding: $input-padding-y-lg $spacer * 0.75;

    &:focus {
      background: $navbar-input-bg;
      box-shadow: none;
      outline: 0;
    }
  }

  .btn {
    color: $gray-600;

    .feather {
      width: 20px;
      height: 20px;
    }
  }
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.7rem;
  font-size: 0.825rem;
  font-weight: 400;
  line-height: 1.625;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e2e8ee;
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
}
