.stat {
  background: $stat-bg;
  border-radius: 50%;
  padding: 0.75rem;
  width: 48px;
  height: 48px;

  svg {
    width: 24px;
    height: 24px;
    color: $stat-icon-color !important;
  }
}

.stat-sm {
  width: 40px;
  height: 40px;
  padding: 0.625rem;

  svg {
    width: 20px;
    height: 20px;
  }
}
