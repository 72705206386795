.rs-picker.rs-picker-tree {
  .rs-picker-toggle {
    &.rs-btn {
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 3.2px;
      &:hover,
      &:focus {
        border: 1px solid rgb(222, 230, 237) !important;
      }
      &:focus-visible {
        outline-style: none !important;
      }
      outline-style: none !important;
      .rs-picker-toggle-value {
        color: $gray-700 !important;
      }
      .rs-picker-toggle-indicator {
        .rs-btn-close {
          top: 4px;
        }
      }
    }
  }
}
