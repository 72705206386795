.table {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: $table-border-color;
  }
}

.card {
  > .dataTables_wrapper .table.dataTable,
  > .table,
  > .table-responsive .table,
  > .table-responsive-sm .table,
  > .table-responsive-md .table,
  > .table-responsive-lg .table,
  > .table-responsive-xl .table {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0;

    td:first-child,
    th:first-child {
      border-left: 0;
      padding-left: $spacer * 1.25;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
      padding-right: $spacer * 1.25;
    }

    tr:first-child td,
    tr:first-child th {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }

  .card-header + .table {
    border-top: 0;
  }
}

.table-action {
  a {
    color: $gray-600;

    &:hover {
      color: $gray-900;
    }
  }

  .feather {
    width: 18px;
    height: 18px;
  }
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.card > .dataTables_wrapper {
  .table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .dataTables_info {
    padding: 1rem 1.25rem;
  }

  .dataTables_paginate {
    padding: 0.6rem 1.25rem;
  }
}

.dt-bootstrap4 {
  width: calc(100% - 2px);
}

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  --bs-table-accent-bg: $primary;
}

// overwrite color changing on rows
.dont-alternate-row-color {
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-bg-type: none !important;
    color: rgb(41 48 66) !important;
  }
  .table > :not(caption) > * > * {
    background-color: inherit;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
}

.td-expand-button {
  cursor: pointer;
}

// overwrite border on rows
.table-bordered > :not(caption) > * > * {
  border-width: 0;
}

.table-head-font-size {
  font-size: 14px;
  font-weight: 700;
}

.table-body-font-size {
  font-size: 14px;
}

.table-header-gray {
  border-width: 0 !important;
  border-bottom-width: 0.175rem !important;
  border-bottom-color: #6c757d !important;
}

.table-bottom-gray {
  border-width: 0 !important;
  border-top-width: 0.175rem !important;
  border-top-color: #6c757d !important;
}

.table-header {
  --bs-table-bg-type: rgb(255 255 255);
}

.bpp-warning {
  --bs-bg-opacity: 1;
  background-color: #fdf3d8 !important;
}
.bpp-danger {
  --bs-bg-opacity: 1;
  background-color: #fadbd8 !important;
}

.bpp-success {
  --bs-bg-opacity: 1;
  background-color: #dbf2e3 !important;
}
